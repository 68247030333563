import Moto from '../assets/svg/icones/moto.svg';
import Hatch from '../assets/svg/icones/hatch.svg';
import Sedan from '../assets/svg/icones/sedan.svg';
import Suv from '../assets/svg/icones/suv.svg';
import Pickup from '../assets/svg/icones/pickup.svg';

export default function formatCategoria(cat) {
  switch (cat) {
    case 0:
      return (
        <span>
          <img src={Moto} alt="Moto" className="categoria-icon" />
          Moto
        </span>
      );

    case 1:
      return (
        <span>
          <img src={Hatch} alt="Hatch" className="categoria-icon" />
          Hatch
        </span>
      );

    case 2:
      return (
        <span>
          <img src={Sedan} alt="Sedan" className="categoria-icon" />
          Sedan
        </span>
      );

    case 3:
      return (
        <span>
          <img src={Suv} alt="SUV" className="categoria-icon" />
          SUV
        </span>
      );

    case 4:
      return (
        <span>
          <img src={Pickup} alt="Pickup" className="categoria-icon" />
          Pickup
        </span>
      );

    default:
      return 'Nenhum';
  }
}
