import React, { useEffect, useRef, useState } from 'react';
import {
  FiFilter,
  FiSettings,
  FiToggleLeft,
  FiFileText,
  FiServer,
  FiCameraOff,
  FiCheck,
} from 'react-icons/fi';
import {
  FaChevronDown,
  FaChevronLeft,
  FaChevronRight,
  FaWheelchair,
  FaMotorcycle,
  FaCheck,
  FaBan,
} from 'react-icons/fa';
import { MdSettingsRemote } from 'react-icons/md';
import { GiHomeGarage } from 'react-icons/gi';
import Pagination from 'react-paginate';
import L from 'leaflet';
import {
  Button,
  Card,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledCollapse,
  UncontrolledDropdown,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  TabContent,
  TabPane,
  Nav,
  NavLink,
  NavItem,
} from 'reactstrap';
import { Form } from '@unform/web';
import { MapContainer, Marker, TileLayer } from 'react-leaflet';
import { useLocation, useHistory } from 'react-router-dom';
import { Input, Select } from '../../components/unform/index';
import api from '../../services/api';
import { useValidator } from '../../hooks';
import MarkerImg from '../../assets/images/marker.png';
import 'leaflet/dist/leaflet.css';
import formatCategoria from '../../utils/formatCategoria';

function formatData(data) {
  const dataSplitArray = data?.split('T')[0]?.split('-');
  return `${dataSplitArray[2]}/${dataSplitArray[1]}/${dataSplitArray[0]}`;
}

function formataCPF(cpf) {
  // retira os caracteres indesejados...
  cpf = cpf?.replace(/[^\d]/g, '');

  // realizar a formatação...
  return cpf?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
}

function formatMinutesToHours(minutes) {
  const hours = Math.floor(minutes / 60); // Obtém a parte inteira das horas
  const remainingMinutes = minutes % 60; // Obtém o restante dos minutos

  // Formatação para exibir sempre 2 dígitos para as horas e minutos
  const formattedHours = `0${hours}`.slice(-2);
  const formattedMinutes = `0${remainingMinutes}`.slice(-2);

  return `${formattedHours}:${formattedMinutes}`;
}

const MyCustomMarker = L.Icon.extend({
  options: {
    shadowUrl: null,
    iconAnchor: new L.Point(12, 12),
    iconSize: new L.Point(20, 20),
    iconUrl: MarkerImg,
  },
});

function Garagens() {
  const location = useLocation();
  const history = useHistory();

  const { showLoader, closeLoader, toast } = useValidator();

  const filtrosRef = useRef();

  const [filtro, setFiltro] = useState({});
  const [dados, setDados] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalGaragem, setModalGaragem] = useState(false);

  const [infos, setInfos] = useState(null);
  const [infosGaragem, setInfosGaragem] = useState(null);

  const toggleModal = () => setModal(!modal);
  const toggleModalGaragem = () => setModalGaragem(!modalGaragem);

  const [activeTab, setActiveTab] = useState('Geral');
  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const [activeTabGaragem, setActiveTabGaragem] = useState('Mapa');
  const toggleTabGaragem = (tab) => {
    if (activeTab !== tab) setActiveTabGaragem(tab);
  };

  async function getDados(page) {
    showLoader();
    try {
      const params = Object.keys(filtro)
        .map((e) => {
          if (e === 'page') {
            if (Number.isNaN(filtro[e])) {
              return `${e}=${1}`;
            }
          }
          return `${e}=${filtro[e]}`;
        })
        .join('&');

      const { data } = await api.get(`/garagens?page=${page || 1}&${params}`);

      setDados(data);
    } catch (err) {
      toast('Erro ao buscar dados!', { type: 'error' });
    }
    closeLoader();
  }

  let timeoutConsulta;
  function filtrarConsulta(e, value) {
    setFiltro({ ...filtro, [value]: e });
  }
  const debounceConsulta = async (body, value) =>
    new Promise((resolve) => {
      clearTimeout(timeoutConsulta);

      timeoutConsulta = setTimeout(() => {
        resolve(filtrarConsulta(body, value));
      }, 500);
    });

  useEffect(() => {
    if (location?.state) {
      const idGaragem = location?.state;
      filtrosRef.current.setFieldValue('id', idGaragem);
      debounceConsulta(idGaragem, 'id');

      history.replace();
    }
  }, []);

  useEffect(() => {
    getDados();
  }, [filtro]);

  async function ativaInativa(id, status) {
    showLoader();
    try {
      const body = {
        status: status === 'Ativo' ? 'Inativo' : 'Ativo',
      };

      await api.put(`/ativarinativar/garagem/${id}`, body);
      toast('Ativado/Inativado com sucesso!', { type: 'success' });
      getDados();
    } catch (err) {
      toast('Erro ao ativar/inativar!', { type: 'error' });
    }
    closeLoader();
  }

  async function verInfos(id, toggle = true) {
    showLoader();
    try {
      const { data } = await api.get(`/dados/usuario/${id}`);
      setInfos(data);
      if (toggle) {
        toggleModal();
      }
    } catch (err) {
      toast('Erro ao buscar dados!', { type: 'error' });
    }
    closeLoader();
  }

  async function ativarGaragem(id) {
    showLoader();
    try {
      await api.patch(`/ativar/garagem/${id}`);
      toast('Ativado com sucesso!', { type: 'success' });
      await getDados();
    } catch (err) {
      toast('Erro ao ativar!', { type: 'error' });
    }
    closeLoader();
  }

  async function ativarVeiculo(id) {
    showLoader();
    try {
      await api.patch(`/ativar/veiculo/${id}`);
      toast('Ativado com sucesso!', { type: 'success' });
      await verInfos(infos?.usuario?.id, false);
    } catch (err) {
      toast('Erro ao ativar!', { type: 'error' });
    }
    closeLoader();
  }

  async function dadosGaragem(id) {
    showLoader();
    try {
      const { data } = await api.get(`/dados/garagem/${id}`);
      setInfosGaragem(data);
      toggleModalGaragem();
    } catch (err) {
      toast('Erro ao buscar dados garagem!', { type: 'error' });
    }
    closeLoader();
  }

  return (
    <>
      <Card>
        <div className="p-3 justify-content-between d-flex align-items-center">
          <h6 className="txt-muted">Total de registros: {dados?.count || 0}</h6>
          <Button
            color="muted"
            id="toggler"
            className="d-flex align-items-center"
          >
            <FiFilter color="rgba(var(--theme-color-rgb), 0.6)" size={20} />
            &nbsp;&nbsp;Filtros
          </Button>
        </div>
        <div className="filter-collapse px-3 pb-3">
          <UncontrolledCollapse toggler="#toggler">
            <Form ref={filtrosRef}>
              <Row>
                <Col>
                  <Input
                    label="ID"
                    className="form-control"
                    placeholder="ID da garagem"
                    name="id"
                    onChange={(e) => debounceConsulta(e.target.value, 'id')}
                  />
                </Col>
                <Col>
                  <Input
                    label="CEP"
                    className="form-control"
                    placeholder="CEP da garagem"
                    name="cep"
                    onChange={(e) => debounceConsulta(e.target.value, 'cep')}
                  />
                </Col>
                <Col>
                  <Input
                    label="Logradouro"
                    className="form-control"
                    placeholder="Logradouro da garagem"
                    name="logradouro"
                    onChange={(e) =>
                      debounceConsulta(e.target.value, 'logradouro')
                    }
                  />
                </Col>
                <Col>
                  <Input
                    label="Bairro"
                    className="form-control"
                    placeholder="Bairro da garagem"
                    name="bairro"
                    onChange={(e) => debounceConsulta(e.target.value, 'bairro')}
                  />
                </Col>
                <Col>
                  <Input
                    label="Estado"
                    className="form-control"
                    placeholder="Estado da garagem"
                    name="estado"
                    onChange={(e) => debounceConsulta(e.target.value, 'estado')}
                  />
                </Col>
                <Col>
                  <Select
                    name="status"
                    label="Status"
                    className="form-control"
                    data={[
                      { label: 'Todos', value: '' },
                      { label: 'Ativo', value: 'Ativo' },
                      { label: 'Inativo', value: 'Inativo' },
                      { label: 'Pendente', value: 'Pendente' },
                    ]}
                    onChange={(e) => debounceConsulta(e.target.value, 'status')}
                  />
                </Col>
                <Col>
                  <Select
                    name="disponibilidade_vagas"
                    label="Disponibilidade vaga"
                    className="form-control"
                    data={[
                      { label: 'Todos', value: '' },
                      { label: 'Locado', value: 'Locado' },
                      { label: 'Disponivel', value: 'Disponivel' },
                      { label: 'Reservado', value: 'Reservado' },
                    ]}
                    onChange={(e) =>
                      debounceConsulta(e.target.value, 'disponibilidade_vagas')
                    }
                  />
                </Col>
              </Row>
            </Form>
          </UncontrolledCollapse>
        </div>

        <Table className="rwd-table" responsive>
          <thead>
            <tr>
              <th>CEP</th>
              <th>Logradouro</th>
              <th>N°</th>
              <th>Bairro</th>
              <th>Cidade</th>
              <th>Estado</th>
              <th>País</th>
              <th>Complemento</th>
              <th>Status</th>
              <th className="text-end">Ação</th>
            </tr>
          </thead>
          <tbody>
            {dados?.data?.map((m) => (
              <tr key={m?.id}>
                <td data-label="CEP">{m?.cep}</td>
                <td data-label="Logradouro">{m?.logradouro}</td>
                <td data-label="N°">{m?.numero}</td>
                <td data-label="Bairro">{m?.bairro}</td>
                <td data-label="Cidade">{m?.cidade}</td>
                <td data-label="Estado">{m?.estado}</td>
                <td data-label="País">{m?.pais}</td>
                <td data-label="Complemento">{m?.complemento}</td>
                <td data-label="Status">{m?.status}</td>
                <td
                  data-label="Ação"
                  className="d-flex justify-content-lg-end justify-content-sm-between"
                >
                  <UncontrolledDropdown>
                    <DropdownToggle
                      color="link"
                      className="text-decoration-none padding-0-5"
                    >
                      <FiSettings className="mr-2" /> <FaChevronDown />
                    </DropdownToggle>
                    <DropdownMenu end>
                      <DropdownItem onClick={() => dadosGaragem(m?.id)}>
                        <FiServer className="mr-1" />
                        Mapa e vagas
                      </DropdownItem>
                      {m?.status !== 'Pendente' ? (
                        <DropdownItem
                          onClick={() => ativaInativa(m?.id, m?.status)}
                        >
                          <FiToggleLeft className="mr-1" />
                          Inativar/Ativar
                        </DropdownItem>
                      ) : (
                        <DropdownItem
                          onClick={() => ativarGaragem(m?.id, m?.status)}
                        >
                          <FiToggleLeft className="mr-1" />
                          Ativar Garagem
                        </DropdownItem>
                      )}
                      <DropdownItem onClick={() => verInfos(m?.id_usuario)}>
                        <FiFileText className="mr-1" />
                        Ver dados do usuário
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Pagination
          nextLabel={<FaChevronRight />}
          previousLabel={<FaChevronLeft />}
          pageCount={dados?.pages || 1}
          onPageChange={({ selected }) => getDados(selected)}
          initialPage={0}
          containerClassName="pagination justify-end mr-1"
          activeClassName="active"
        />
      </Card>

      <Modal
        isOpen={modal}
        toggle={() => {
          toggleModal();
          setActiveTab('Geral');
          setInfos(null);
        }}
        size="xl"
      >
        <ModalHeader
          toggle={() => {
            toggleModal();
            setActiveTab('Geral');
            setInfos(null);
          }}
        >
          Dados do usuário
        </ModalHeader>
        <ModalBody>
          <Nav tabs>
            <NavItem>
              <NavLink
                active={activeTab === 'Geral'}
                onClick={() => {
                  toggleTab('Geral');
                }}
              >
                Geral
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                active={activeTab === 'Endereco'}
                onClick={() => {
                  toggleTab('Endereco');
                }}
              >
                Endereço
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                active={activeTab === 'Veiculos'}
                onClick={() => {
                  toggleTab('Veiculos');
                }}
              >
                Veículos
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                active={activeTab === 'Garagens'}
                onClick={() => {
                  toggleTab('Garagens');
                }}
              >
                Garagens
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                active={activeTab === 'Documentos'}
                onClick={() => {
                  toggleTab('Documentos');
                }}
              >
                Documentos
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent activeTab={activeTab}>
            <TabPane tabId="Geral">
              <Row className="mt-4">
                <Col>
                  <h6 style={{ textAlign: 'center' }}>
                    <strong>Nome:</strong>
                    <span style={{ marginLeft: '10px' }}>
                      {infos?.usuario?.nome}
                    </span>
                  </h6>
                  <hr />
                </Col>
                <Col>
                  <h6 style={{ textAlign: 'center' }}>
                    <strong>E-mail:</strong>
                    <span style={{ marginLeft: '10px' }}>
                      {infos?.usuario?.email}
                    </span>
                  </h6>
                  <hr />
                </Col>
                <Col>
                  <h6 style={{ textAlign: 'center' }}>
                    <strong>CPF:</strong>
                    <span style={{ marginLeft: '10px' }}>
                      {formataCPF(infos?.usuario?.cpf)}
                    </span>
                  </h6>
                  <hr />
                </Col>
              </Row>
              <Row>
                <Col>
                  <h6 style={{ textAlign: 'center' }}>
                    <strong>Telefone:</strong>
                    <span style={{ marginLeft: '10px' }}>
                      {infos?.usuario?.telefone}
                    </span>
                  </h6>
                  <hr />
                </Col>
                <Col>
                  <h6 style={{ textAlign: 'center' }}>
                    <strong>Nascimento:</strong>
                    <span style={{ marginLeft: '10px' }}>
                      {infos?.usuario?.data_nascimento &&
                        formatData(infos?.usuario?.data_nascimento)}
                    </span>
                  </h6>
                  <hr />
                </Col>
                <Col>
                  <h6 style={{ textAlign: 'center' }}>
                    <strong>Status:</strong>
                    <span style={{ marginLeft: '10px' }}>
                      {infos?.usuario?.status}
                    </span>
                  </h6>
                  <hr />
                </Col>
              </Row>
              <Row>
                <Col>
                  <h6 style={{ textAlign: 'center' }}>
                    <strong>Última visita:</strong>
                    <span style={{ marginLeft: '10px' }}>
                      {infos?.usuario?.ultima_visita &&
                        formatData(infos?.usuario?.ultima_visita)}
                    </span>
                  </h6>
                  <hr />
                </Col>
                {/* <Col>
                  <h6 style={{ textAlign: 'center' }}>
                    <strong>ID Zoop:</strong>
                    <span style={{ marginLeft: '10px' }}>
                      {infos?.usuario?.comprador_id_zoop}
                    </span>
                  </h6>
                  <hr />
                </Col>
                <Col>
                  <h6 style={{ textAlign: 'center' }}>
                    <strong>Token Zoop:</strong>
                    <span style={{ marginLeft: '10px' }}>
                      {infos?.usuario?.token_cartao_zoop}
                    </span>
                  </h6>
                  <hr />
                </Col> */}
              </Row>
              <Row>
                <Col>
                  <h6 style={{ textAlign: 'center' }}>
                    <strong>Como conheceu:</strong>
                    <span style={{ marginLeft: '10px' }}>
                      {infos?.usuario?.como_conheceu}
                    </span>
                  </h6>
                  <hr />
                </Col>
                <Col>
                  <h6 style={{ textAlign: 'center' }}>
                    <strong>Marketing:</strong>
                    <span style={{ marginLeft: '10px' }}>
                      {infos?.usuario?.marketing ? 'Sim' : 'Não'}
                    </span>
                  </h6>
                  <hr />
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="Endereco">
              <Row className="mt-4">
                <Col lg={3}>
                  <h6 style={{ textAlign: 'center' }}>
                    <strong>CEP:</strong>
                    <span style={{ marginLeft: '10px' }}>
                      {infos?.endereco?.cep}
                    </span>
                  </h6>
                  <hr />
                </Col>
                <Col>
                  <h6 style={{ textAlign: 'center' }}>
                    <strong>Logradouro:</strong>
                    <span style={{ marginLeft: '10px' }}>
                      {infos?.endereco?.logradouro}
                    </span>
                  </h6>
                  <hr />
                </Col>
                <Col lg={2}>
                  <h6 style={{ textAlign: 'center' }}>
                    <strong>N°:</strong>
                    <span style={{ marginLeft: '10px' }}>
                      {infos?.endereco?.numero}
                    </span>
                  </h6>
                  <hr />
                </Col>
              </Row>
              <Row>
                <Col>
                  <h6 style={{ textAlign: 'center' }}>
                    <strong>Cidade:</strong>
                    <span style={{ marginLeft: '10px' }}>
                      {infos?.endereco?.cidade}
                    </span>
                  </h6>
                  <hr />
                </Col>
                <Col>
                  <h6 style={{ textAlign: 'center' }}>
                    <strong>Bairro:</strong>
                    <span style={{ marginLeft: '10px' }}>
                      {infos?.endereco?.bairro}
                    </span>
                  </h6>
                  <hr />
                </Col>
                <Col>
                  <h6 style={{ textAlign: 'center' }}>
                    <strong>Estado:</strong>
                    <span style={{ marginLeft: '10px' }}>
                      {infos?.endereco?.estado}
                    </span>
                  </h6>
                  <hr />
                </Col>
                <Col>
                  <h6 style={{ textAlign: 'center' }}>
                    <strong>País:</strong>
                    <span style={{ marginLeft: '10px' }}>
                      {infos?.endereco?.pais}
                    </span>
                  </h6>
                  <hr />
                </Col>
              </Row>
              <Row>
                <Col>
                  <h6 style={{ textAlign: 'center' }}>
                    <strong>Complemento:</strong>
                    <span style={{ marginLeft: '10px' }}>
                      {infos?.endereco?.complemento}
                    </span>
                  </h6>
                  <hr />
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="Veiculos">
              <Table className="rwd-table" responsive>
                <thead>
                  <tr>
                    <th>Placa</th>
                    <th>Cor</th>
                    <th>Ano</th>
                    <th>Marca</th>
                    <th>Modelo</th>
                    <th>Tipo</th>
                    <th>Tamanho</th>
                    <th>Status</th>
                    <th className="text-end">Ação</th>
                  </tr>
                </thead>
                <tbody>
                  {infos?.veiculos?.map((m) => (
                    <tr key={m?.id}>
                      <td data-label="Placa">{m?.placa}</td>
                      <td data-label="Cor">{m?.cor}</td>
                      <td data-label="Ano">{m?.ano}</td>
                      <td data-label="Marca">{m?.marca}</td>
                      <td data-label="Modelo">{m?.modelo}</td>
                      <td data-label="Tipo">
                        {m?.tipo === 'carro' ? 'Carro' : 'Moto'}
                      </td>
                      <td data-label="Tamanho">
                        {formatCategoria(m?.categoria_tamanho)}
                      </td>
                      <td data-label="Status">{m?.status}</td>
                      <td
                        data-label="Ação"
                        className="d-flex justify-content-lg-end justify-content-sm-between"
                      >
                        {m?.status !== 'Ativo' ? (
                          <UncontrolledDropdown>
                            <DropdownToggle
                              color="link"
                              className="text-decoration-none padding-0-5"
                            >
                              <FiSettings className="mr-2" /> <FaChevronDown />
                            </DropdownToggle>
                            <DropdownMenu end>
                              {m?.status !== 'Ativo' && (
                                <DropdownItem
                                  onClick={() => ativarVeiculo(m?.id)}
                                >
                                  <FiCheck className="mr-1" />
                                  Ativar Veículo
                                </DropdownItem>
                              )}
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        ) : (
                          <p>Sem ações</p>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </TabPane>
            <TabPane tabId="Garagens">
              <Table className="rwd-table" responsive>
                <thead>
                  <tr>
                    <th>CEP</th>
                    <th>Logradouro</th>
                    <th>N°</th>
                    <th>Bairro</th>
                    <th>Cidade</th>
                    <th>Estado</th>
                    <th>País</th>
                    <th>Complemento</th>
                    <th>Status</th>
                    <th className="text-end">Ação</th>
                  </tr>
                </thead>
                <tbody>
                  {infos?.garagens?.map((m) => (
                    <tr key={m?.id}>
                      <td data-label="CEP">{m?.cep}</td>
                      <td data-label="Logradouro">{m?.logradouro}</td>
                      <td data-label="N°">{m?.numero}</td>
                      <td data-label="Bairro">{m?.bairro}</td>
                      <td data-label="Cidade">{m?.cidade}</td>
                      <td data-label="Estado">{m?.estado}</td>
                      <td data-label="País">{m?.pais}</td>
                      <td data-label="Complemento">{m?.complemento}</td>
                      <td data-label="Status">{m?.status}</td>
                      <td
                        data-label="Ação"
                        className="d-flex justify-content-lg-end justify-content-sm-between"
                      >
                        <UncontrolledDropdown>
                          <DropdownToggle
                            color="link"
                            className="text-decoration-none padding-0-5"
                          >
                            <FiSettings className="mr-2" /> <FaChevronDown />
                          </DropdownToggle>
                          <DropdownMenu end>
                            <DropdownItem onClick={() => dadosGaragem(m?.id)}>
                              <FiServer className="mr-1" />
                              Mapa e vagas
                            </DropdownItem>
                            {m?.status !== 'Ativo' && (
                              <DropdownItem
                                onClick={() => ativarGaragem(m?.id)}
                              >
                                <FiCheck className="mr-1" />
                                Ativar Garagem
                              </DropdownItem>
                            )}
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </TabPane>
          </TabContent>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggleModal}>
            Ok
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={modalGaragem}
        toggle={() => {
          toggleModalGaragem();
          setInfosGaragem(null);
          setActiveTabGaragem('Mapa');
        }}
        size="xl"
      >
        <ModalHeader
          toggle={() => {
            toggleModalGaragem();
            setInfosGaragem(null);
            setActiveTabGaragem('Mapa');
          }}
        >
          Mapa e vagas
        </ModalHeader>
        <ModalBody>
          <Nav tabs>
            <NavItem>
              <NavLink
                active={activeTabGaragem === 'Mapa'}
                onClick={() => {
                  toggleTabGaragem('Mapa');
                }}
              >
                Mapa
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                active={activeTabGaragem === 'Vagas'}
                onClick={() => {
                  toggleTabGaragem('Vagas');
                }}
              >
                Vagas
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent activeTab={activeTabGaragem}>
            <TabPane tabId="Mapa">
              <Row>
                <Col>
                  <h5>
                    Latitude e logitude garagem:{' '}
                    <strong>{infosGaragem?.garagem?.latitude}</strong>,
                    <strong>{infosGaragem?.garagem?.longitude}</strong>
                  </h5>
                </Col>
              </Row>
              {infosGaragem && (
                <MapContainer
                  center={[
                    Number(infosGaragem?.garagem?.latitude),
                    Number(infosGaragem?.garagem?.longitude),
                  ]}
                  zoom={18}
                  style={{
                    width: '100%',
                    height: '450px',
                  }}
                >
                  <TileLayer
                    attribution="&copy; CARTO"
                    url="https://{s}.basemaps.cartocdn.com/rastertiles/{id}/{z}/{x}/{y}{r}.png"
                    subdomains="abcd"
                    maxZoom={20}
                    id="voyager_labels_under"
                  />
                  <Marker
                    key="marker"
                    position={[
                      Number(infosGaragem?.garagem?.latitude),
                      Number(infosGaragem?.garagem?.longitude),
                    ]}
                    icon={new MyCustomMarker()}
                  />
                </MapContainer>
              )}
            </TabPane>
            <TabPane tabId="Vagas">
              <Row className="mt-4">
                <Col>
                  <Table className="rwd-table" responsive>
                    <thead>
                      <tr>
                        <th>Identificador</th>
                        <th>Valor Mensalidade</th>
                        <th className="text-center">Categoria Máx.</th>
                        <th className="text-center">Especificações</th>
                        <th className="text-center">Fotos</th>
                        <th className="text-center">Status</th>
                        {/* <th className="text-end">Ação</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {infosGaragem?.garagem_vagas?.map((m) => (
                        <>
                          <tr
                            key={m?.id}
                            style={{ borderTop: '2px solid gray' }}
                          >
                            <td data-label="Identificador">
                              {m?.identificador_vaga}
                            </td>
                            <td data-label="Valor Mensalidade">
                              {Number(m?.valor_mensalidade).toLocaleString(
                                'pt-BR',
                                {
                                  style: 'currency',
                                  currency: 'BRL',
                                }
                              )}
                            </td>
                            <td
                              data-label="Categoria Máx."
                              className="text-center"
                            >
                              {formatCategoria(m?.categoria_tamanho_max)}
                            </td>
                            <td
                              data-label="Especificações"
                              className="text-center"
                            >
                              {m?.aceita_moto && (
                                <FaMotorcycle
                                  size={25}
                                  title="Aceita moto"
                                  className="mr-1"
                                />
                              )}
                              {m?.vaga_coberta && (
                                <GiHomeGarage
                                  size={20}
                                  title="Vaga coberta"
                                  className="mr-1"
                                />
                              )}
                              {m?.vaga_especial && (
                                <FaWheelchair
                                  size={20}
                                  title="Vaga especial"
                                  className="mr-1"
                                />
                              )}
                              {m?.portao_eletronico && (
                                <MdSettingsRemote
                                  size={22}
                                  title="Portão eletrônico"
                                  className="mr-1"
                                />
                              )}
                            </td>
                            <td data-label="Fotos" className="text-center">
                              {m?.VagaImagems.length ? (
                                m.VagaImagems.map((foto, index) => (
                                  <a
                                    key={foto?.id}
                                    href={foto?.Imagem?.path}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <img
                                      src={foto?.Imagem?.path}
                                      alt={`Foto ${index + 1}`}
                                      className="foto-thumbnail"
                                    />
                                  </a>
                                ))
                              ) : (
                                <FiCameraOff size={25} />
                              )}
                            </td>
                            <td data-label="Status" className="text-center">
                              {m?.status}
                            </td>
                          </tr>
                          {m?.VagaDisponibilidade ? (
                            <tr key={`${m?.id}_disponibilidade`}>
                              <td colSpan="6">
                                <Row className="mt-2 mb-2">
                                  <Col lg={2}>
                                    <div>
                                      <strong>Status: </strong>

                                      {m?.VagaDisponibilidade?.status ===
                                      'Locado' ? (
                                        <span
                                          style={{
                                            backgroundColor: 'green',
                                            padding: 5,
                                            color: 'white',
                                            borderRadius: 50,
                                            fontSize: 12,
                                          }}
                                        >
                                          {m?.VagaDisponibilidade?.status}
                                        </span>
                                      ) : (
                                        <span>
                                          {m?.VagaDisponibilidade?.status}
                                        </span>
                                      )}
                                    </div>
                                  </Col>
                                  <Col lg={5}>
                                    <div style={{ display: 'flex' }}>
                                      <div className="mr-1">
                                        <strong>Seg: </strong>
                                        {m?.VagaDisponibilidade?.segunda ? (
                                          <FaCheck size={10} color="green" />
                                        ) : (
                                          <FaBan size={10} color="red" />
                                        )}
                                      </div>
                                      <div className="mr-1">
                                        <strong>Ter: </strong>
                                        {m?.VagaDisponibilidade?.terca ? (
                                          <FaCheck size={10} color="green" />
                                        ) : (
                                          <FaBan size={10} color="red" />
                                        )}
                                      </div>
                                      <div className="mr-1">
                                        <strong>Qua: </strong>
                                        {m?.VagaDisponibilidade?.quarta ? (
                                          <FaCheck size={10} color="green" />
                                        ) : (
                                          <FaBan size={10} color="red" />
                                        )}
                                      </div>
                                      <div className="mr-1">
                                        <strong>Qui: </strong>
                                        {m?.VagaDisponibilidade?.quinta ? (
                                          <FaCheck size={10} color="green" />
                                        ) : (
                                          <FaBan size={10} color="red" />
                                        )}
                                      </div>
                                      <div className="mr-1">
                                        <strong>Sex: </strong>
                                        {m?.VagaDisponibilidade?.sexta ? (
                                          <FaCheck size={10} color="green" />
                                        ) : (
                                          <FaBan size={10} color="red" />
                                        )}
                                      </div>
                                      <div className="mr-1">
                                        {formatMinutesToHours(
                                          m?.VagaDisponibilidade
                                            ?.hora_minutos_de
                                        )}
                                        <strong> as </strong>
                                        {formatMinutesToHours(
                                          m?.VagaDisponibilidade
                                            ?.hora_minutos_ate
                                        )}
                                      </div>
                                    </div>
                                  </Col>
                                  <Col lg={5}>
                                    <div style={{ display: 'flex' }}>
                                      <div className="mr-1">
                                        <strong>Sáb: </strong>
                                        {m?.VagaDisponibilidade?.sabado ? (
                                          <FaCheck size={10} color="green" />
                                        ) : (
                                          <FaBan size={10} color="red" />
                                        )}
                                      </div>
                                      <div className="mr-1">
                                        {formatMinutesToHours(
                                          m?.VagaDisponibilidade
                                            ?.sabado_hora_minutos_de
                                        )}
                                        <strong> as </strong>
                                        {formatMinutesToHours(
                                          m?.VagaDisponibilidade
                                            ?.sabado_hora_minutos_ate
                                        )}
                                      </div>
                                      <div className="mr-1">
                                        <strong>Dom: </strong>
                                        {m?.VagaDisponibilidade?.domingo ? (
                                          <FaCheck size={10} color="green" />
                                        ) : (
                                          <FaBan size={10} color="red" />
                                        )}
                                      </div>
                                      <div className="mr-1">
                                        {formatMinutesToHours(
                                          m?.VagaDisponibilidade
                                            ?.domingo_hora_minutos_de
                                        )}
                                        <strong> as </strong>
                                        {formatMinutesToHours(
                                          m?.VagaDisponibilidade
                                            ?.domingo_hora_minutos_ate
                                        )}
                                      </div>
                                      <div className="mr-1">
                                        <strong>Feri: </strong>
                                        {m?.VagaDisponibilidade
                                          ?.aceita_feriado ? (
                                          // eslint-disable-next-line prettier/prettier
                                            <FaCheck size={10} color="green" />
                                        ) : (
                                          <FaBan size={10} color="red" />
                                        )}
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              </td>
                            </tr>
                          ) : (
                            <tr>
                              <td colSpan="6" className="text-center">
                                <strong>Sem disponibilidade cadastrada</strong>
                              </td>
                            </tr>
                          )}
                        </>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggleModal}>
            Ok
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default Garagens;
