import { Form } from '@unform/web';
import React, { useEffect, useRef } from 'react';
import { Button, Card, CardBody, CardFooter, Col, Row } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { Image, TextArea } from '../../components/unform';
import { useValidator } from '../../hooks';
import api from '../../services/api';

function Perfil() {
  const { showLoader, closeLoader, toast } = useValidator();
  const history = useHistory();
  const formRef = useRef();

  async function handleSubmit(e) {
    if (e.descricao) {
      const formData = new FormData();
      formData.append('descricao', e.descricao);

      showLoader();
      const { error } = await api.post('/lojas', formData).catch((err) => {
        toast(err.mensagem);
        return { error: true };
      });
      closeLoader();
      if (!error) {
        toast('Loja alterada', { type: 'success' });
        history.goBack();
      }
    }
  }

  async function handleChange(e) {
    if (!e.foto) {
      return;
    }

    const formData = new FormData();
    formData.append('file', e.foto);
    formData.append('field', e.field);

    showLoader();
    const { error } = await api.post('/lojas', formData).catch((err) => {
      toast(err.mensagem);
      return { error: true };
    });
    closeLoader();
    if (!error) {
      toast('Foto alterada', { type: 'success' });
    }
  }

  useEffect(() => {
    const getData = async () => {
      const { data } = await api.get('/me/loja');
      formRef.current.setFieldValue('id_imagem_banner', data?.img_banner?.path);
      formRef.current.setFieldValue('id_imagem_perfil', data?.img_perfil?.path);
      formRef.current.setFieldValue('descricao', data?.descricao);
    };
    getData();
  }, []);

  return (
    <>
      <Form onSubmit={(e) => handleSubmit(e)} ref={formRef}>
        <Card>
          <CardBody>
            <Row>
              <Col lg={3}>
                <h5>Foto loja</h5>
                <Image
                  name="id_imagem_perfil"
                  btnSelect="Alterar"
                  objectFit="contain"
                  radius={false}
                  onChange={(e) => {
                    handleChange({
                      foto: e.file,
                      field: 'id_imagem_perfil',
                    });
                  }}
                />
              </Col>
              <Col lg={6}>
                <h5>Banner da loja</h5>
                <Image
                  name="id_imagem_banner"
                  btnSelect="Alterar"
                  objectFit="cover"
                  radius={false}
                  width="100%"
                  onChange={(e) => {
                    handleChange({
                      foto: e.file,
                      field: 'id_imagem_banner',
                    });
                  }}
                />
              </Col>
              <Col lg={12}>
                <h5>Breve descrição loja</h5>
                <TextArea name="descricao" className="form-control" />
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <Button color="primary">Salvar</Button>
          </CardFooter>
        </Card>
      </Form>
    </>
  );
}

export default Perfil;
