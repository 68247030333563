/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState, useRef } from 'react';
import { Form } from '@unform/web';
import { useReactToPrint } from 'react-to-print';
import {
  TabContent,
  Nav,
  NavItem,
  NavLink,
  Button,
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  InputGroup,
  InputGroupText,
  Table,
  CardTitle,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
} from 'reactstrap';
import {
  HiDotsHorizontal,
  HiOutlineXCircle,
  HiOutlinePlusCircle,
  HiMinus,
  HiInformationCircle,
  HiCheckCircle,
  HiStar,
  HiArchive,
  HiSearch,
  HiX,
} from 'react-icons/hi';
import InfiniteScroll from 'react-infinite-scroll-component';
import Flatpickr from 'react-flatpickr';
import { Portuguese } from 'flatpickr/dist/l10n/pt';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import api from '../../services/api';

import logo from '../../assets/svg/logo.svg';
import BouncingDotsLoader from '../../components/boucingDots';
import { useValidator } from '../../hooks/index';
import formFiltros from '../../utils/formFiltros';
import { Input, ReactSelect } from '../../components/unform';

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'ml-3 btn btn-success',
    cancelButton: 'btn btn-danger',
  },
  reverseButtons: true,
  buttonsStyling: false,
});
function adicionaZero(numero) {
  if (numero <= 9) {
    return `0${numero}`;
  }
  return numero;
}

function formataData(data) {
  const date = new Date(data);

  return `${adicionaZero(date.getDate().toString())}/${adicionaZero(
    date.getMonth() + 1
  ).toString()}/${date.getFullYear()} - ${adicionaZero(
    date.getHours()
  )}:${adicionaZero(date.getMinutes())}`;
}

function formataDataSemHora(data) {
  const date = new Date(data);

  return `${adicionaZero(date.getDate().toString())}/${adicionaZero(
    date.getMonth() + 1
  ).toString()}/${date.getFullYear()}`;
}

function condicaoPagamento(id) {
  if (id === '87eee61f-d89a-4624-a0a5-f41f6aacf421') {
    return 'PIX';
  }
  if (id === 'f8c3e908-5e10-4733-9a8d-98965427561d') {
    return 'Cartão crédito/débito';
  }
}

const Grid = ({
  data = [],
  toggleModal,
  setItensPedido,
  getDeclaracao,
  setInfosEnvio,
  verInfosEnvio,
  infosGerais,
  cancelarPedido,
}) => (
  <>
    {data?.map((m) => (
      <Card className="p-1" key={m?.id}>
        <div className="bg-gray1">
          <CardHeader>
            <CardTitle>
              <Row>
                <Col lg={3}>
                  <strong>Parceiro:&nbsp;&nbsp;</strong>
                  {m?.Parceiro?.nome_fantasia}
                </Col>
                <Col lg={3}>
                  <strong>Cliente:&nbsp;&nbsp;</strong>
                  {m?.Cliente?.nome}
                </Col>
                <Col lg={3}>
                  <strong>Telefone:&nbsp;&nbsp;</strong>
                  {m?.Cliente?.telefone}
                </Col>
                <Col lg={3}>
                  <strong>ID pedido:&nbsp;&nbsp;</strong>
                  {m?.id}
                </Col>
              </Row>
            </CardTitle>
          </CardHeader>
        </div>
        <CardBody>
          <Row>
            <Col lg={4}>
              {m?.PedidoDetalhes?.map((pde) => (
                <Row key={pde?.id}>
                  <Col lg={12}>
                    <div className="d-flex align-items-center mb-2">
                      <img
                        width={60}
                        height={70}
                        className="object-fit bg-gray1"
                        src={pde?.Produto?.ProdutoImagems[0]?.Imagem?.path}
                        alt={pde?.Produto?.titulo}
                      />
                      <div className="ml-3">
                        <p className="bold mb-0">{pde?.Produto?.titulo}</p>
                        <p className="mb-0">{pde?.Produto?.Autor?.nome}</p>
                      </div>
                    </div>
                  </Col>
                </Row>
              ))}
            </Col>
            <Col lg={8}>
              <Row>
                <Col lg={2}>
                  <Row>
                    <Col>
                      <p>
                        <strong>Total com frete</strong>
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p>
                        {Number(m?.total_pedido).toLocaleString('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </p>
                    </Col>
                  </Row>
                </Col>
                <Col lg={2}>
                  <Row>
                    <Col>
                      <p>
                        <strong>Data pedido</strong>
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p>{formataData(m?.data_hora_pedido)}</p>
                    </Col>
                  </Row>
                </Col>
                <Col lg={2}>
                  <Row>
                    <Col>
                      <p>
                        <strong>Data entrega</strong>
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p>{m?.data_entrega && formataData(m?.data_entrega)}</p>
                    </Col>
                  </Row>
                </Col>
                <Col lg={2}>
                  <Row>
                    <Col>
                      <p>
                        <strong>Status pedido</strong>
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p className="no-wrap">{m?.status}</p>
                    </Col>
                  </Row>
                </Col>
                <Col lg={2}>
                  <Row>
                    <Col>
                      <p>
                        <strong>Status pgto.</strong>
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p>{m?.status_pagamento}</p>
                    </Col>
                  </Row>
                </Col>
                <Col lg={2}>
                  <Row>
                    <Col>
                      <p>
                        <strong>Opções</strong>
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <UncontrolledDropdown className="float-md">
                        <DropdownToggle
                          color="link"
                          className="text-decoration-none padding-0-5"
                        >
                          <HiDotsHorizontal />
                        </DropdownToggle>
                        <DropdownMenu direction="down">
                          <DropdownItem
                            onClick={() => {
                              infosGerais(m?.id);
                              setItensPedido({
                                id_pedido: m?.id,
                                itens: m?.PedidoDetalhes,
                                parceiro: m?.Parceiro?.nome_fantasia,
                                tipo_frete: m?.tipo_frete,
                              });
                              setInfosEnvio({
                                id: m?.id,
                                dados_cliente: m?.Cliente,
                                itens: m?.PedidoDetalhes,
                                tipo_frete: m?.tipo_frete,
                                endereco: m?.Endereco,
                              });
                            }}
                          >
                            <HiInformationCircle className="mr-1" />
                            Infos do pedido
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              getDeclaracao(m?.id);
                            }}
                          >
                            <HiArchive className="mr-1" />
                            Declaração de conteúdo
                          </DropdownItem>
                          {m?.status === 'Aguardando Envio' && (
                            <>
                              <DropdownItem
                                onClick={() => {
                                  toggleModal();
                                  infosGerais(m?.id, 'envio');
                                  setInfosEnvio({
                                    id: m?.id,
                                    dados_cliente: m?.Cliente,
                                    itens: m?.PedidoDetalhes,
                                    tipo_frete: m?.tipo_frete,
                                    endereco: m?.Endereco,
                                  });
                                }}
                              >
                                <HiInformationCircle className="mr-1" />
                                Dados p/ envio
                              </DropdownItem>
                            </>
                          )}

                          {m?.status === 'Enviado' && (
                            <DropdownItem
                              onClick={() => {
                                verInfosEnvio(m?.id);
                              }}
                            >
                              <HiInformationCircle className="mr-1" />
                              Infos. envio
                            </DropdownItem>
                          )}
                          {m?.status !== 'Finalizado' &&
                            m?.status !== 'Enviado' &&
                            m?.status !== 'Cancelado' &&
                            m?.status !== 'Devolução/Reembolso' && (
                              <DropdownItem
                                onClick={() => cancelarPedido(m?.id)}
                              >
                                <HiOutlineXCircle className="mr-1" />
                                Cancelar
                              </DropdownItem>
                            )}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </Col>
                  </Row>
                </Col>
              </Row>
              {(m?.status === 'Cancelado' ||
                m?.status === 'Devolução/Reembolso') && (
                <>
                  <Row className="mt-4">
                    <Col>
                      <p>
                        <strong>Pedido cancelado pelo:</strong>
                      </p>
                      <p>
                        {m?.CancelamentoPedido?.cancelamento_parceiro
                          ? 'Parceiro'
                          : 'Cliente'}
                      </p>
                    </Col>
                    <Col>
                      <p>
                        <strong>Motivos cancelamento</strong>
                      </p>
                      <p>{m?.CancelamentoPedido?.cancelamento_motivo}</p>
                    </Col>
                  </Row>
                </>
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>
    ))}
  </>
);

function Pedidos() {
  const history = useHistory();
  const { showLoader, closeLoader, toast } = useValidator();

  const envioRef = useRef();
  const cancelarRef = useRef();
  const debouncePedido = useRef();

  const [dados, setDados] = useState({});
  const [modal, setModal] = useState(false);
  const [offcanvas, setOffcanvas] = useState(false);
  const [modalEnvio, setModalEnvio] = useState(false);
  const [hasMore, sethasMore] = useState(true);
  const [modalDeclaracao, setModalDeclaracao] = useState(null);
  const [infosEnvio, setInfosEnvio] = useState(null);
  const [itensPedido, setItensPedido] = useState([]);
  const [activeTab, setActiveTab] = useState('Todos');
  const [qtdEnvios, setQtdEnvios] = useState({ val: [] });
  const [idPedidoCancelar, setIdPedidoCancelar] = useState(null);
  const [infosGeraisDados, setInfosGeraisDados] = useState(null);
  const [modalInfosGerais, setModalInfosGerais] = useState(false);
  const [infosEnvioRastreio, setInfosEnvioRastreio] = useState([]);
  const [offcanvasCancelar, setOffcanvasCancelar] = useState(false);

  const [chegouAoFim, setChegouAoFim] = useState(false);

  const toggleModal = () => setModal(!modal);
  const toggleOffcanvas = () => setOffcanvas(!offcanvas);
  const toggleModalEnvio = () => setModalEnvio(!modalEnvio);
  const toggleModalInfos = () => setModalInfosGerais(!modalInfosGerais);
  const toggleOffcanvasCancelar = () =>
    setOffcanvasCancelar(!offcanvasCancelar);

  const loadMore = async (page) => {
    const nextPage = (page === 0 ? page : dados?.page || 1) + 1;

    const filtros = `page=${nextPage}&${dados?.filtros?.join('&') || ''}`;

    const { data } = await api.get(`/pedidos?${filtros}`).catch((err) => {
      toast(err.mensagem, { type: 'error' });
      return null;
    });

    if (!data?.data?.length) {
      setChegouAoFim(true);
    } else {
      setChegouAoFim(false);
    }

    if (nextPage === 1) {
      setDados(data);
    } else {
      setDados((old) => ({
        ...old,
        page: nextPage,
        data: (old?.data || []).concat(data?.data || []),
      }));
    }
  };

  async function handleSubmit(tab) {
    const body = formFiltros();

    const active = typeof tab === 'string' ? tab : activeTab;

    const filtros = Object.entries(body)
      .map(([key, value]) => (value ? `${key}=${value || ''}` : ''))
      .filter(Boolean);

    if (active !== 'Todos') {
      if (active === 'Esgotado') {
        filtros.push('estoque_disponivel=0');
      } else {
        filtros.push(`status=${active}`);
      }
    }

    try {
      showLoader();
      const { data } = await api.get(`/pedidos?${filtros.join('&')}`);

      if (!data?.data?.length || data?.data?.length === 1) {
        setChegouAoFim(true);
      } else {
        setChegouAoFim(false);
      }

      setDados({
        ...data,
        filtros,
      });
    } catch (err) {
      toast(err.mensagem, { type: 'error' });
    }
    closeLoader();
  }

  async function handleMontarEnvio(data, tipo, id) {
    const errors = {};
    Object.keys(data).forEach((f) => {
      if (!data[f]) {
        errors[f] = 'Obrigatório';
      } else if (f.includes('itens_')) {
        if (!data[f].length) {
          errors[f] = 'Obrigatório';
        }
      }
    });
    envioRef.current.setErrors(errors);
    if (!Object.keys(errors).length) {
      showLoader();
      try {
        const body = {
          envios: [],
          tipo,
          id_pedido: id,
          pedidos_detalhe_verificar: [],
        };
        Object.keys(data).forEach((f) => {
          if (f.includes('rastreio_')) {
            const envioNumber = f.split('_')[1];
            Object.keys(data).forEach((e) => {
              if (e === `itens_${envioNumber}`) {
                body.envios.push({
                  rastreamento: data[f],
                  pedido_detalhe: data[e],
                });
              }
            });
          }
        });
        body.envios.forEach((e) => {
          e.pedido_detalhe.forEach((p) => {
            body.pedidos_detalhe_verificar.push(p);
          });
        });
        await api.post('/montar-envio', body);
        toggleOffcanvas();
        loadMore(0);
        toast('Pedido atualizado com sucesso!', { type: 'success' });
      } catch (err) {
        toast(err?.mensagem, { type: 'error' });
      }
      closeLoader();
    }
  }

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      handleSubmit(tab);
    }
  };

  useEffect(() => {
    const initialData = async () => {
      showLoader();
      const { data } = await api.get('/pedidos').catch((err) => {
        toast(err.mensagem, { type: 'error' });
        return {};
      });
      sethasMore(data.data.length >= 10);
      setDados(data);
      closeLoader();
    };
    clearTimeout(debouncePedido.current);
    debouncePedido.current = setTimeout(() => {
      initialData();
    }, 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleChange(event) {
    const vals = [...qtdEnvios.val];
    vals[this] = event.target.value;
    setQtdEnvios({ val: vals });
  }

  const addClick = () => {
    setQtdEnvios({ val: [...qtdEnvios.val, ''] });
  };

  const removeClick = (event) => {
    const vals = [...qtdEnvios.val];
    const index = Number(event.target.name);
    vals.splice(index, 1);
    setQtdEnvios({ val: vals });
  };

  function createInputs() {
    return qtdEnvios.val.map((el, i) => (
      <Card key={i}>
        <CardBody>
          <Row>
            <Col lg={12}>
              <ReactSelect
                label="Itens envio"
                name={`itens_${i}`}
                isMulti
                options={itensPedido?.itens?.map((m) => ({
                  label: m?.Produto?.titulo,
                  value: m?.id,
                }))}
              />
            </Col>
            <Col lg={12}>
              <Input
                type="text"
                className="form-control"
                label="Código de rastreio"
                name={`rastreio_${i}`}
                value={el || ''}
                onChange={handleChange.bind(i)}
              />
            </Col>
          </Row>
        </CardBody>
        <CardFooter>
          <Button
            color="danger"
            type="button"
            name={i}
            block
            className="btn-sm"
            onClick={removeClick.bind(i)}
          >
            Remover <HiMinus />
          </Button>
        </CardFooter>
      </Card>
    ));
  }

  const componentRef = useRef();
  const componentDeclaracaoRef = useRef();
  const printPdf = useReactToPrint({
    content: () => componentRef.current,
    bodyClass: 'align-items-start view-print print-envio',
    removeAfterPrint: true,
  });
  const printPdfDeclaracao = useReactToPrint({
    content: () => componentDeclaracaoRef.current,
    bodyClass: 'align-items-start view-print',
    removeAfterPrint: true,
  });

  // function printPdf(pedido) {
  //   htmlToImage
  //     .toPng(document.getElementById('print'), { quality: 1 })
  //     .then((dataUrl) => {
  //       const link = document.createElement('a');
  //       link.download = 'pedido.jpeg';
  //       // eslint-disable-next-line new-cap
  //       const pdf = new jsPDF();
  //       pdf.addImage(dataUrl, 'PNG', 0, 0);
  //       pdf.save(`so_sebos_pedido_${pedido}.pdf`);
  //     });
  // }

  async function verInfosEnvio(idPedido) {
    showLoader();
    try {
      const { data } = await api.get(`/dados-envio/${idPedido}`);
      setInfosEnvioRastreio(data);
      toggleModalEnvio();
    } catch (err) {
      toast('Erro ao buscar dados do envio!', { type: 'error' });
    }
    closeLoader();
  }

  async function infosGerais(idPedido, tipo) {
    showLoader();
    try {
      const { data } = await api.get(`/get-infos-pedido-envio/${idPedido}`);

      setInfosGeraisDados(data);
      if (tipo !== 'envio') {
        toggleModalInfos();
      }
    } catch (err) {
      toast('Erro ao buscar dados do pedido!', { type: 'error' });
    }
    closeLoader();
  }

  async function getDeclaracao(idPedido) {
    showLoader();
    try {
      const { data } = await api.get(`/pedidos/${idPedido}/conteudo`);
      setInfosGeraisDados(data);
      setModalDeclaracao(true);
    } catch (err) {
      toast('Erro ao buscar dados do pedido!', { type: 'error' });
    }
    closeLoader();
  }

  function cancelarPedido(idPedido) {
    toggleOffcanvasCancelar();
    setIdPedidoCancelar(idPedido);
  }

  async function handleCancelarPedido(data) {
    showLoader();
    const errors = {};
    Object.keys(data).forEach((f) => {
      if (!data[f]) {
        errors[f] = 'Obrigatório';
      }
    });
    cancelarRef.current.setErrors(errors);
    if (!Object.keys(errors).length) {
      try {
        const body = {
          id_pedido: idPedidoCancelar,
          cancelamento_motivo: data.cancelamento_motivo,
        };
        await api.post('/cancela-reembolso-pedido', body);
        loadMore(0);
        toggleOffcanvasCancelar();
        toast('Pedido cancelado com sucesso!', { type: 'success' });
      } catch (err) {
        toast('Erro ao cancelar pedido!', { type: 'error' });
      }
    }
    closeLoader();
  }

  return (
    <>
      <Card>
        <CardHeader>
          <Nav tabs>
            <NavItem>
              <NavLink
                active={activeTab === 'Todos'}
                onClick={() => {
                  toggle('Todos');
                  setChegouAoFim(false);
                }}
              >
                Todos
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                active={activeTab === 'Pendente'}
                onClick={() => {
                  toggle('Pendente');
                  setChegouAoFim(false);
                }}
              >
                Não pago
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                active={activeTab === 'Aguardando Envio'}
                onClick={() => {
                  toggle('Aguardando Envio');
                  setChegouAoFim(false);
                }}
              >
                A enviar
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                active={activeTab === 'Enviado'}
                onClick={() => {
                  toggle('Enviado');
                  setChegouAoFim(false);
                }}
              >
                Enviado
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                active={activeTab === 'Entregue'}
                onClick={() => {
                  toggle('Entregue');
                  setChegouAoFim(false);
                }}
              >
                Entregue
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                active={activeTab === 'Concluído'}
                onClick={() => {
                  toggle('Concluído');
                  setChegouAoFim(false);
                }}
              >
                Concluído
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                active={activeTab === 'Cancelado'}
                onClick={() => {
                  toggle('Cancelado');
                  setChegouAoFim(false);
                }}
              >
                Cancelado
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                active={activeTab === 'Devolução/Reembolso'}
                onClick={() => {
                  toggle('Devolução/Reembolso');
                  setChegouAoFim(false);
                }}
              >
                Devolução/Reembolso
              </NavLink>
            </NavItem>
          </Nav>
        </CardHeader>
        <CardBody>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              const data = formFiltros();
              handleSubmit(data);
            }}
          >
            <Row>
              <Col md={10}>
                <Row>
                  <Col lg={4} className="mb-3">
                    <InputGroup className="custom">
                      <InputGroupText>
                        <select name="consulta">
                          <option value="id">ID Pedido</option>
                          <option value="cliente">Nome cliente</option>
                        </select>
                      </InputGroupText>
                      <input
                        placeholder=""
                        style={{ height: ' 2.714rem' }}
                        name="valor"
                      />
                    </InputGroup>
                  </Col>
                  <Col lg={4} className="mb-3">
                    <InputGroup className="custom">
                      <InputGroupText>
                        <div>Data de criação do pedido</div>
                      </InputGroupText>

                      <Flatpickr
                        label="date ranger"
                        name="data_criacao"
                        className="form-control border-none"
                        options={{
                          mode: 'range',
                          locale: Portuguese,
                          dateFormat: 'd/m/Y',
                        }}
                      />
                    </InputGroup>
                  </Col>
                  <Col lg={4} className="mb-3">
                    <InputGroup className="custom">
                      <InputGroupText>
                        <div>ID parceiro</div>
                      </InputGroupText>
                      <input
                        placeholder=""
                        style={{ height: ' 2.714rem' }}
                        name="id_parceiro"
                      />
                    </InputGroup>
                  </Col>
                </Row>
              </Col>
              <Col md={2} className="d-flex-wrap justify-content-end">
                <div>
                  <Button
                    size="lg"
                    className="mr-2"
                    color="light-danger"
                    type="reset"
                  >
                    <HiX /> Lim.
                  </Button>
                </div>
                <div>
                  <Button color="light-primary" size="lg" type="submit">
                    <HiSearch /> Pes.
                  </Button>
                </div>
              </Col>
            </Row>
          </form>
          <Row className="mt-4">
            <Col lg={4}>
              <h5>
                {dados?.count} Pedido{dados?.count > 1 ? 's' : ''}
              </h5>
            </Col>
            {/* <Col lg={8} className="d-flex justify-content-end">
              <Button color="primary mx-2" className="mb-3">
                Ação em massa
              </Button>
            </Col> */}
          </Row>
          <TabContent activeTab={activeTab}>
            <InfiniteScroll
              className="row stop"
              style={{ minHeight: 350 }}
              dataLength={dados?.data?.length || 0}
              hasMore={
                // dados?.data?.length !== 0
                // !Object.keys(dados || {}).length ||
                // (dados?.data?.length > 0 && dados?.data?.length < dados?.count)
                !chegouAoFim
              }
              next={() => {
                loadMore();
              }}
              loader={
                <div className="my-3">
                  <BouncingDotsLoader />
                </div>
              }
              endMessage={
                <p style={{ textAlign: 'center' }}>
                  <b>Não há mais dados</b>
                </p>
              }
              scrollableTarget="main-content"
              refreshFunction={() => loadMore(0)}
              pullDownToRefresh
            >
              <Grid
                data={dados?.data}
                status={activeTab}
                toggle={(id) => toggleOffcanvas(id)}
                toggleModal={toggleModal}
                setItensPedido={setItensPedido}
                setInfosEnvio={setInfosEnvio}
                verInfosEnvio={verInfosEnvio}
                infosGerais={infosGerais}
                cancelarPedido={cancelarPedido}
                getDeclaracao={getDeclaracao}
                setInfosGeraisDados={setInfosGeraisDados}
              />
            </InfiniteScroll>
          </TabContent>
        </CardBody>
      </Card>

      <Modal isOpen={modal} toggle={toggleModal} size="lg">
        <ModalHeader toggle={toggleModal}>Dados para envio</ModalHeader>
        <div ref={componentRef}>
          <ModalBody id="print">
            <Row className="infos-envio">
              <Col lg={2}>
                <img
                  src={logo}
                  className="object-fit img-envio"
                  alt="logo simbolo"
                />
              </Col>
              <Col lg={10}>
                <Row>
                  <Col>
                    <h6 className="mb-3">Tipo de entrega:</h6>
                    <h6>
                      <strong>{infosEnvio?.tipo_frete}</strong>
                    </h6>
                  </Col>
                </Row>
                <div className="hr" />
                <Row>
                  <Col>
                    <h6 className="mb-3">Destinatário:</h6>
                    <h6>
                      <strong>{infosEnvio?.dados_cliente?.nome}</strong>
                    </h6>
                  </Col>
                  <Col>
                    <h6 className="mb-3">CPF:</h6>
                    <h6>
                      <strong>{infosEnvio?.dados_cliente?.cpf}</strong>
                    </h6>
                  </Col>
                  <Col lg={3}>
                    <h6 className="mb-3">CEP:</h6>
                    <h6>
                      <strong>{infosEnvio?.endereco?.cep}</strong>
                    </h6>
                  </Col>
                  <Col lg={3}>
                    <h6 className="mb-3">Telefone:</h6>
                    <h6>
                      <strong>{infosEnvio?.dados_cliente?.telefone}</strong>
                    </h6>
                  </Col>
                </Row>
                <div className="hr" />
                <Row>
                  <Col>
                    <h6 className="mb-3">Endereço:</h6>
                    <h6>
                      <strong>
                        {infosEnvio?.endereco?.logradouro}, Nº{' '}
                        {infosEnvio?.endereco?.numero},{' '}
                        {infosEnvio?.endereco?.bairro}
                        <br />
                        {infosEnvio?.endereco?.cidade} -{' '}
                        {infosEnvio?.endereco?.estado}
                      </strong>
                    </h6>
                  </Col>
                  <Col lg={3}>
                    <h6 className="mb-3">Complemento:</h6>
                    <h6>
                      <strong>{infosEnvio?.endereco?.complemento}</strong>
                    </h6>
                  </Col>
                </Row>
                {/* <div className="hr" />
                <Row>
                  <Col>
                    <h6 className="mb-3">Endereço remetente:</h6>
                    <h6>
                      {infosGeraisDados?.enderecoParceiro ? (
                        <strong>
                          {infosGeraisDados?.enderecoParceiro?.logradouro}, Nº{' '}
                          {infosGeraisDados?.enderecoParceiro?.numero},{' '}
                          {infosGeraisDados?.enderecoParceiro?.bairro}
                          <br />
                          {infosGeraisDados?.enderecoParceiro?.cidade} -{' '}
                          {infosGeraisDados?.enderecoParceiro?.estado}
                        </strong>
                      ) : (
                        <p />
                      )}
                    </h6>
                  </Col>
                </Row> */}
              </Col>
            </Row>
            <Row>
              <div className="hr mt-5" />
              <Row className="justify-end">
                <Col>
                  <h6 className="mb-3">Pedido:</h6>
                  <h6>
                    <strong>{infosEnvio?.id}</strong>
                  </h6>
                </Col>
              </Row>
              <Table className="rwd-table" responsive>
                <thead>
                  <tr>
                    <th className="text-center">Valor total</th>
                    <th className="text-center">Valor sem frete</th>
                    <th className="text-center">Valor frete</th>
                    <th className="text-center">Desconto total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td data-label="Valor total" className="text-center">
                      {Number(infosGeraisDados?.total_pedido).toLocaleString(
                        'pt-BR',
                        {
                          style: 'currency',
                          currency: 'BRL',
                        }
                      )}
                    </td>
                    <td data-label="Valor sem frete" className="text-center">
                      {Number(infosGeraisDados?.valor_produto).toLocaleString(
                        'pt-BR',
                        {
                          style: 'currency',
                          currency: 'BRL',
                        }
                      )}
                    </td>
                    <td data-label="Valor frete" className="text-center">
                      {Number(infosGeraisDados?.taxa_frete).toLocaleString(
                        'pt-BR',
                        {
                          style: 'currency',
                          currency: 'BRL',
                        }
                      )}
                    </td>
                    <td data-label="Desconto total" className="text-center">
                      {Number(infosGeraisDados?.valor_desconto).toLocaleString(
                        'pt-BR',
                        {
                          style: 'currency',
                          currency: 'BRL',
                        }
                      )}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Row>
            <Row>
              <Col className="justify-center mt-5">
                <h5>Itens</h5>
              </Col>
            </Row>
            <div className="hr" />
            <Row>
              <Col>
                <Table className="rwd-table" responsive>
                  <thead>
                    <tr>
                      <th>Tipo</th>
                      <th>Condição</th>
                      <th>Nome</th>
                      <th>Autor</th>
                      <th>Editora</th>
                      <th>Ano</th>
                      <th>Idioma</th>
                      <th>Estante</th>
                    </tr>
                  </thead>
                  <tbody>
                    {infosEnvio?.itens?.map((i) => (
                      <>
                        <tr key={i?.Produto?.id}>
                          <td data-label="Tipo">{i?.Produto?.tipo}</td>
                          <td data-label="Condição">{i?.Produto?.condicao}</td>
                          <td data-label="Nome">{i?.Produto?.titulo}</td>
                          <td data-label="Autor">{i?.Produto?.Autor?.nome}</td>
                          <td data-label="Editora">
                            {i?.Produto?.Editora?.nome}
                          </td>
                          <td data-label="Ano">{i?.Produto?.ano}</td>
                          <td data-label="Idioma">{i?.Produto?.idioma}</td>
                          <td data-label="Estante">{i?.Produto?.estante}</td>
                        </tr>
                        <tr>
                          <td data-label="Desc Estado" colSpan={8}>
                            <p>Desc. Estado: {i?.Produto?.descricao_estado}</p>
                          </td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </ModalBody>
        </div>
        <ModalFooter>
          <Button color="primary" onClick={() => printPdf(infosEnvio?.id)}>
            Imprimir
          </Button>
          <Button color="primary" onClick={() => getDeclaracao(infosEnvio?.id)}>
            Declaração de conteúdo
          </Button>
          <Button color="secondary" onClick={toggleModal}>
            Ok
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={modalDeclaracao}
        toggle={() => setModalDeclaracao(false)}
        size="xl"
      >
        <ModalHeader toggle={() => setModalDeclaracao(false)}>
          Declaração de conteúdo correios
        </ModalHeader>
        <ModalBody>
          <div id="printDeclaracao" ref={componentDeclaracaoRef}>
            <Row>
              <Col>
                <Row className="justify-end">
                  <Col>
                    <h5 className="text-center upper bold">
                      Declaração de conteúdo
                    </h5>
                  </Col>
                </Row>
                <div className="hr" />
                <Row>
                  <Col
                    xs={6}
                    className="br-2"
                    style={{
                      marginRight: -2,
                    }}
                  >
                    <p className="upper">Unidade de postagem</p>
                    <br />
                  </Col>
                  <Col xs={6}>
                    <p className="upper">Número de registro</p>
                    <br />
                  </Col>
                </Row>
                <div className="hr" />
                <Row className="upper">
                  <Col
                    xs={6}
                    className="br-2"
                    style={{
                      marginRight: -2,
                    }}
                  >
                    <h6 className="mb-3 bold text-center">remetente</h6>
                    <h6 className="bb-2 pb-2">
                      <b>NOME: </b>
                      {infosGeraisDados?.Parceiro?.razao_social
                        ? infosGeraisDados?.Parceiro?.razao_social
                        : infosGeraisDados?.Parceiro?.responsavel}
                    </h6>
                    <h6 className="bb-2 pb-2">
                      <b>ENDEREÇO: </b>
                      {infosGeraisDados?.EnderecoParceiro?.logradouro},{' '}
                      {infosGeraisDados?.EnderecoParceiro?.numero}
                      {infosGeraisDados?.EnderecoParceiro?.complemento
                        ? ` ${infosGeraisDados?.EnderecoParceiro?.complemento}`
                        : ''}
                      , {infosGeraisDados?.EnderecoParceiro?.bairro}
                    </h6>
                    <div className="d-flex bb-2">
                      <h6 className="w-60 br-2 mr-2">
                        <b>CIDADE: </b>
                        {infosGeraisDados?.EnderecoParceiro?.cidade}
                      </h6>
                      <h6 className="w-40">
                        <b>UF: </b>
                        {infosGeraisDados?.EnderecoParceiro?.estado}
                      </h6>
                    </div>
                    <div className="d-flex mt-2">
                      <h6 className="w-40 br-2 mr-2">
                        <b>CEP: </b>
                        {infosGeraisDados?.EnderecoParceiro?.cep}
                      </h6>
                      <h6 className="w-60">
                        <b>CPF:/CNPJ: </b>
                        {infosGeraisDados?.Parceiro?.cnpj}
                      </h6>
                    </div>
                  </Col>
                  <Col xs={6}>
                    <h6 className="mb-3 bold text-center">destinatário</h6>
                    <h6 className="bb-2 pb-2">
                      <b>NOME: </b>
                      {infosGeraisDados?.Cliente?.nome}
                    </h6>
                    <h6 className="bb-2 pb-2">
                      <b>ENDEREÇO: </b>
                      {infosGeraisDados?.EnderecoCliente?.logradouro},{' '}
                      {infosGeraisDados?.EnderecoCliente?.numero}
                      {infosGeraisDados?.EnderecoCliente?.complemento
                        ? ` ${infosGeraisDados?.EnderecoCliente?.complemento}`
                        : ''}
                      , {infosGeraisDados?.EnderecoCliente?.bairro}
                    </h6>
                    <div className="d-flex bb-2">
                      <h6 className="w-60 br-2 mr-2">
                        <b>CIDADE: </b>
                        {infosGeraisDados?.EnderecoCliente?.cidade}
                      </h6>
                      <h6 className="w-40">
                        <b>UF: </b>
                        {infosGeraisDados?.EnderecoCliente?.estado}
                      </h6>
                    </div>
                    <div className="d-flex mt-2">
                      <h6 className="w-40 mr-2 br-2">
                        <b>CEP: </b>
                        {infosGeraisDados?.EnderecoCliente?.cep}
                      </h6>
                      <h6 className="w-60">
                        <b>CPF:/CNPJ: </b>
                        {infosGeraisDados?.Cliente?.cpf}
                      </h6>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <div className="hr" />
            <Row>
              <Col>
                <h6 className="text-center mt-2 upper bold">
                  Identificação de bens
                </h6>
              </Col>
            </Row>
            <Row className="upper">
              <Col>
                <Table className="rwd-table" responsive>
                  <thead>
                    <tr>
                      <th className="text-center">item</th>
                      <th className="text-center">conteúdo</th>
                      <th className="text-center">QUANT.</th>
                    </tr>
                  </thead>
                  <tbody>
                    {infosGeraisDados?.items?.map((i, index) => (
                      <tr key={i?.id}>
                        <td className="text-center" data-label="item">
                          {index + 1}
                        </td>
                        <td data-label="conteúdo">{i?.nome}</td>
                        <td className="text-center" data-label="QUANT.">
                          {i?.qtd}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <th
                        scope="row"
                        className="text-end"
                        style={{
                          background: 'rgba(var(--theme-color-rgb), 0.12)',
                        }}
                        colSpan={2}
                      >
                        TOTAIS
                      </th>
                      <td className="text-center">
                        {infosGeraisDados?.resumo?.qtd}
                      </td>
                    </tr>
                    <tr>
                      <th
                        scope="row"
                        className="text-end"
                        colSpan={2}
                        style={{
                          background: 'rgba(var(--theme-color-rgb), 0.12)',
                        }}
                      >
                        PESO TOTAL(KG)
                      </th>
                      <td className="text-center">
                        {Number(
                          infosGeraisDados?.resumo?.peso / 1000
                        ).toLocaleString('pt-BR', {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                        })}
                      </td>
                    </tr>
                  </tfoot>
                </Table>
              </Col>
            </Row>
            <div className="hr" />
            <Row>
              <Col>
                <h6 className="text-center mt-2 upper bold">Declaração</h6>
              </Col>
            </Row>
            <Row>
              <Col>
                <p className="p-small">
                  Declaro que não me enquadro no conceito de contribuinte
                  previsto no art. 4º da Lei Complementar nº 87/1996, uma vez
                  que não realizo, com habitualidade ou em volume que
                  caracterize intuito comercial, operações de circulação de
                  mercadoria, ainda que se iniciem no exterior, ou estou
                  dispensado da emissão da nota fiscal por força da legislação
                  tributária vigente, responsabilizando-me, nos termos da lei e
                  a quem de direito, por informações inverídicas. Declaro ainda
                  que não estou postando conteúdo inflamável, explosivo,
                  causador de combustão espontânea, tóxico, corrosivo, gás ou
                  qualquer outro conteúdo que constitua perigo, conforme o art.
                  13 da Lei Postal nº 6.538/78.
                </p>
                <p className="p-small">
                  _________________________ , _______ de __________________
                  de________________________________
                </p>
                <p className="text-center w-100 mb-1">
                  Assinatura do Declarante/Remetente
                </p>
                <p className="text-center w-100 mb-0">
                  ____________________________________________________________________________
                </p>
              </Col>
            </Row>
            <div className="hr" />
            <h6 className="mt-2 bold">OBSERVAÇÃO:</h6>
            <p className="p-small">
              Constitui crime contra a ordem tributária suprimir ou reduzir
              tributo, ou contribuição social e qualquer acessório (Lei 8.137/90
              Art. 1º, V).
            </p>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => {
              printPdfDeclaracao();
            }}
          >
            Salvar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modalEnvio} toggle={toggleModalEnvio} size="lg">
        <ModalHeader toggle={toggleModalEnvio}>Dados do envio</ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <Table className="rwd-table" responsive>
                <thead>
                  <tr>
                    <th>Item</th>
                    <th>Código</th>
                    <th>Status entrega</th>
                  </tr>
                </thead>
                <tbody>
                  {infosEnvioRastreio?.map((i) => (
                    <tr key={i?.id}>
                      <td data-label="Item">{i?.item}</td>
                      <td data-label="Código">{i?.codigo_rastreio}</td>
                      <td data-label="Status entrega">{i?.status_entrega}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleModalEnvio}>
            Ok
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modalInfosGerais} toggle={toggleModalInfos} size="lg">
        <ModalHeader toggle={toggleModalInfos}>
          Informações do pedido
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col className="justify-center">
              <strong>ID:</strong>&nbsp;&nbsp;{infosGeraisDados?.id}
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <Table className="rwd-table" responsive>
                <thead>
                  <tr>
                    <th className="text-center">Status</th>
                    <th className="text-center">Status Pagamento</th>
                    <th className="text-center">Tipo de envio</th>
                    <th className="text-center">Avaliação</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td data-label="Status" className="text-center">
                      {infosGeraisDados?.status}
                    </td>
                    <td data-label="Status Pagamento" className="text-center">
                      {infosGeraisDados?.status_pagamento}
                    </td>
                    <td data-label="Tipo de envio" className="text-center">
                      {infosGeraisDados?.tipo_frete}
                    </td>
                    <td data-label="Avaliação" className="text-center">
                      {infosGeraisDados?.avaliacao === 'N' ? (
                        'N/ avaliado'
                      ) : (
                        <div>
                          {infosGeraisDados?.avaliacao}{' '}
                          <HiStar color="orange" />
                        </div>
                      )}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row>
            <Col>
              <Table className="rwd-table" responsive>
                <thead>
                  <tr>
                    <th className="text-center">
                      Método de pagamento escolhido
                    </th>
                    <th className="text-center">Data de pagamento</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td data-label="Status" className="text-center">
                      {infosGeraisDados?.condicao_pagamento
                        ? condicaoPagamento(
                            infosGeraisDados?.condicao_pagamento
                          )
                        : 'Sem pagamento'}
                    </td>
                    <td data-label="Status Pagamento" className="text-center">
                      {infosGeraisDados?.data_hora_pagamento
                        ? formataData(infosGeraisDados?.data_hora_pagamento)
                        : 'Sem data de pagamento'}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row>
            <Col>
              <Table className="rwd-table" responsive>
                <thead>
                  <tr>
                    <th className="text-center">Cupom</th>
                    <th className="text-center">Desconto cupom</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td data-label="Status" className="text-center">
                      {infosGeraisDados?.codigo_cupom || 'Sem cupom'}
                    </td>
                    <td data-label="Status Pagamento" className="text-center">
                      {infosGeraisDados?.valor_cupom
                        ? `${infosGeraisDados?.valor_cupom}%`
                        : 0}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row>
            <Col>
              <Table className="rwd-table" responsive>
                <thead>
                  <tr>
                    <th className="text-center">Valor produtos</th>
                    <th className="text-center">Valor frete</th>
                    <th className="text-center">Desconto</th>
                    <th className="text-center">Valor total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td data-label="Valor produtos" className="text-center">
                      {Number(infosGeraisDados?.valor_produto).toLocaleString(
                        'pt-BR',
                        {
                          style: 'currency',
                          currency: 'BRL',
                        }
                      )}
                    </td>
                    <td data-label="Valor frete" className="text-center">
                      {Number(infosGeraisDados?.taxa_frete).toLocaleString(
                        'pt-BR',
                        {
                          style: 'currency',
                          currency: 'BRL',
                        }
                      )}
                    </td>
                    <td data-label="Desconto" className="text-center">
                      {Number(infosGeraisDados?.valor_desconto).toLocaleString(
                        'pt-BR',
                        {
                          style: 'currency',
                          currency: 'BRL',
                        }
                      )}
                    </td>
                    <td data-label="Valor total" className="text-center">
                      {Number(infosGeraisDados?.total_pedido).toLocaleString(
                        'pt-BR',
                        {
                          style: 'currency',
                          currency: 'BRL',
                        }
                      )}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row>
            <Col>
              <Table className="rwd-table" responsive>
                <thead>
                  <tr>
                    <th className="text-center">Parceiro</th>
                    <th className="text-center">Parceiro recebe</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td data-label="Parceiro" className="text-center">
                      {itensPedido?.parceiro}
                    </td>
                    <td data-label="Parceiro recebe" className="text-center">
                      {Number(
                        infosGeraisDados?.valor_parceiro * 0.9
                      ).toLocaleString('pt-BR', {
                        currency: 'BRL',
                        style: 'currency',
                      })}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
          <div className="hr mb-3" />
          {infosGeraisDados?.PedidoDetalhes?.map((pdt) => (
            <Row key={pdt?.id}>
              <Col>
                <Card>
                  <CardHeader>
                    <strong>{pdt?.nome_produto}</strong>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col lg={1}>
                        <div className="d-flex align-items-center mb-2">
                          <img
                            width={60}
                            height={70}
                            className="object-fit bg-gray1"
                            src={
                              pdt?.Produto?.ProdutoImagems?.[0]?.Imagem?.path
                            }
                            alt={pdt?.Produto?.titulo}
                          />
                        </div>
                      </Col>
                      <Col>
                        <Table className="rwd-table" responsive>
                          <thead>
                            <tr>
                              <th className="text-center">Valor normal</th>
                              <th className="text-center">Valor desconto</th>
                              <th className="text-center">Valor total</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td
                                data-label="Valor total"
                                className="text-center"
                              >
                                {Number(pdt?.valor_normal).toLocaleString(
                                  'pt-BR',
                                  {
                                    style: 'currency',
                                    currency: 'BRL',
                                  }
                                )}
                              </td>
                              <td
                                data-label="Valor sem frete"
                                className="text-center"
                              >
                                {Number(pdt?.valor_desconto).toLocaleString(
                                  'pt-BR',
                                  {
                                    style: 'currency',
                                    currency: 'BRL',
                                  }
                                )}
                              </td>
                              <td
                                data-label="Valor frete"
                                className="text-center"
                              >
                                {Number(pdt?.valor_total).toLocaleString(
                                  'pt-BR',
                                  {
                                    style: 'currency',
                                    currency: 'BRL',
                                  }
                                )}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <p>
                          <strong>Descrição estado: </strong>
                          {pdt?.Produto?.descricao_estado}
                        </p>
                      </Col>
                    </Row>
                    {pdt?.PedidoEntrega && (
                      <Row>
                        <Col lg={6}>
                          <h6>
                            Status entrega: &nbsp;
                            <br />
                            <strong>
                              {pdt?.PedidoEntrega?.status_entrega}
                            </strong>
                          </h6>
                        </Col>
                        <Col>
                          <h6>
                            Código rastreio: &nbsp;
                            <br />
                            <strong>
                              {pdt?.PedidoEntrega?.codigo_rastreio}
                            </strong>
                          </h6>
                        </Col>
                        <Col>
                          <h6>
                            Data entrega: &nbsp;
                            <br />
                            <strong>
                              {pdt?.PedidoEntrega?.data_entrega
                                ? formataDataSemHora(
                                    pdt?.PedidoEntrega?.data_entrega
                                  )
                                : 'Sem data de entrega'}
                            </strong>
                          </h6>
                        </Col>
                      </Row>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ))}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleModalInfos}>
            Ok
          </Button>
        </ModalFooter>
      </Modal>

      <Offcanvas direction="end" isOpen={offcanvas} toggle={toggleOffcanvas}>
        <OffcanvasHeader toggle={toggleOffcanvas}>Montar envio</OffcanvasHeader>
        <OffcanvasHeader>
          <Row>
            <Col>
              <Button
                color="outline-primary"
                className="btn-block"
                onClick={toggleModal}
              >
                Caso ainda não tenha enviado o pedido clique aqui para ver as
                infomações de envio <HiInformationCircle />
              </Button>
            </Col>
          </Row>
        </OffcanvasHeader>
        <OffcanvasBody>
          <Row className="mb-3">
            <Col>
              <strong>Adicione quantidade de envios para esse pedido</strong>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              O cliente escolheu o método de envio:&nbsp;&nbsp;
              <strong>{itensPedido?.tipo_frete}</strong>
            </Col>
          </Row>
          <Form
            ref={envioRef}
            onSubmit={(e) =>
              handleMontarEnvio(
                e,
                itensPedido?.tipo_frete,
                itensPedido?.id_pedido
              )
            }
          >
            {itensPedido?.tipo_frete !== 'Combinar com o vendedor' &&
              createInputs()}
            <>
              <div className="hr" />
              <Row>
                <Col>
                  <Button
                    color="outline-success"
                    className="btn-block"
                    onClick={addClick}
                  >
                    Adicionar <HiOutlinePlusCircle />
                  </Button>
                </Col>
              </Row>
              <div className="hr" />
              {qtdEnvios?.val?.length ? (
                <Row>
                  <Col>
                    <Button color="primary" className="btn-block" type="submit">
                      Salvar <HiCheckCircle />
                    </Button>
                  </Col>
                </Row>
              ) : (
                <div />
              )}
            </>
          </Form>
        </OffcanvasBody>
      </Offcanvas>

      <Offcanvas
        direction="end"
        isOpen={offcanvasCancelar}
        toggle={toggleOffcanvasCancelar}
      >
        <OffcanvasHeader toggle={toggleOffcanvasCancelar}>
          Cancelar pedido
        </OffcanvasHeader>
        <OffcanvasBody>
          <Form ref={cancelarRef} onSubmit={(e) => handleCancelarPedido(e)}>
            <Row>
              <Col>
                <Input
                  type="text"
                  className="form-control"
                  label="Motivo do cancelamento"
                  name="cancelamento_motivo"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Button color="light-warning" type="submit" block>
                  Cancelar
                </Button>
              </Col>
            </Row>
          </Form>
        </OffcanvasBody>
      </Offcanvas>
    </>
  );
}

export default Pedidos;
