import React, { createContext, useState, useEffect, useContext } from 'react';
import api from '../services/api';

const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  async function signOut() {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    setUser(null);
  }

  async function signIn(body) {
    const { data: response } = await api.post('/sessions', body);
    const usuario = response.user || response.usuario;

    if (usuario?.tipo !== 'A') {
      throw new Error('Usuário não autorizado!');
    }
    api.defaults.headers.Authorization = `Bearer ${response.token}`;

    localStorage.setItem('user', JSON.stringify(usuario));
    localStorage.setItem('token', response.token);

    setUser(usuario);
  }

  useEffect(() => {
    async function loadStorageData() {
      api.interceptors.response.use(
        (res) => res,
        (error) => {
          const err = error?.response?.data?.error;
          const errMsg = error?.response?.data?.message;
          error.errors = error?.response?.data?.errors || {};
          error.mensagem =
            (typeof err === 'string' && err) ||
            (typeof errMsg === 'string' && errMsg) ||
            'Ocorreu algum problema, tente novamente mais tarde!';

          error.titulo = 'Ops...';

          if (error?.response?.status === 401) {
            signOut();
          }

          return Promise.reject(error);
        }
      );

      const { user: storagedUser, token: storagedToken } = localStorage;

      if (storagedUser && storagedToken) {
        setUser(JSON.parse(storagedUser));
        api.defaults.headers.Authorization = `Bearer ${storagedToken}`;
      }

      setLoading(false);
    }

    loadStorageData();
  }, []);

  return (
    <AuthContext.Provider
      value={{ signed: !!user, user, loading, signIn, signOut }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider.');
  }

  return context;
}

export { AuthProvider, useAuth };
